.mentions {
  margin: 1em 0;
}

.comment-text--singleLine .comment-text__control,
.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.comment-text--singleLine .comment-text__highlighter,
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.comment-text--singleLine .comment-text__input,
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.comment-text--multiLine .comment-text__highlighter,
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 63px;
}
.comment-text--multiLine .comment-text__input,
.mentions--multiLine .mentions__input {
  border: none !important;
  padding: 9px;
  outline: 0;
}

.comment-text__suggestions__list,
.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  overflow: auto;
  max-height: 200px;
}

.comment-text__suggestions__item,
.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__input {
  border-width: 0;
  &:focus {
    outline: none !important;
    box-shadow: none;
  }
}

.comment-text__highlighter,
.comment-text__input {
  padding-left: 0 !important;
}
