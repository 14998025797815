.default {
  .client-user-session-container {
    @apply bg-primary-800;
  }

  .admin-session-container {
    @apply bg-secondary-50;
  }

  .cm-session-container {
    @apply bg-primary-50;
  }
}

.monash {
  .client-user-session-container,
  .admin-session-container,
  .cm-session-container {
    @apply bg-black;
  }
}
