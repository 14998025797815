.navigation-wrapper {
  @apply bg-primary-50;
}

.monash .navigation-wrapper {
  @apply bg-[#006CAB];
}

.left-nav-link {
  @apply w-full inline-block;
}

.left-nav-link-mobile {
  @apply left-nav-link text-sm px-6 py-2 text-black;

  &.active {
    @apply font-bold;
  }
}

.main-nav.desktop.is-shrink {
  .left-nav-link-item {
    @apply justify-center;
  }
}

.left-nav-link-item {
  @apply flex items-center block whitespace-nowrap text-black subpixel-antialiased;

  .left-nav-link-item__icon {
    @apply text-black;
  }

  &:hover {
    @apply bg-primary-300;
  }

  &.active {
    @apply bg-primary-300;
  }

  &.active:hover {
    @apply bg-primary-300;
  }

  @screen md {
    @apply px-6;
  }
}

.monash .left-nav-link-item,
.monash .main-nav-link__menu-icon {
  &.active {
    @apply bg-[#027EB6];
  }

  &:hover {
    @apply bg-[#027EB6];
  }

  span {
    color: #ffffff;
  }

  svg {
    filter: invert(99%) sepia(0%) saturate(7498%) hue-rotate(49deg)
      brightness(118%) contrast(101%);
  }
}

.help-and-feedback-nav {
  .help-and-feeback-link {
    padding: 0 1.5rem;
    height: 44px;
  }
}
