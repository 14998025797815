// Disable upload file/image, media, table
.ck-file-dialog-button,
.ck.ck-dropdown:not(.ck-heading-dropdown) {
  display: none !important;
}

.ck-editor h1 {
  @apply text-headline3;
}

.ck-editor h2 {
  @apply text-headline4;
}

.ck-editor h3 {
  @apply text-headline5;
}

.ck-editor h4 {
  @apply text-headline6;
}

.ck-editor a {
  @apply text-primary-500 underline outline-none cursor-pointer transition;
}

// /* BLOCKQUOTE TEXT */
.ck-editor blockquote {
  @apply p-4 italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500 my-1;
}

// /* PRE OR CODE TEXT */
.ck-editor pre {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-family: monospace;
  font-size: 0.9em;
  padding: 0.5em;
  white-space: pre;
  background-color: #eee;
  overflow-x: auto;
}

// /* LIST - BULLETS TYPE */
.ck-editor ul {
  @apply list-disc list-inside;
}

.ck-editor ul ul {
  @apply list-circle list-inside;
}

.ck-editor ul ul ul {
  @apply list-square list-inside;
}

.ck-editor ul {
  @apply list-disc list-inside pl-4;
}

.ck-editor ul ul {
  @apply list-circle list-inside pl-4;
}

.ck-editor ul ul ul {
  @apply list-square list-inside pl-4;
}

// /* LIST - NUMBERS TYPE */
.ck-editor ol {
  @apply list-decimal list-inside;
}

.ck-editor ol ol {
  @apply list-alpha list-inside;
}
.ck-editor .ck-placeholder {
  font-style: italic;
  color: rgb(174, 174, 174) !important;
  font-size: 14px;
  opacity: 1;
}
