.simple-calendar {
  & .day {
    @apply h-full;

    &:active {
      @apply bg-legacyColor-neutral-2-700;
    }
  }

  & .past {
    &:active {
      @apply bg-legacyColor-neutral-2-700;
    }
  }
  // &.wday-0 {
  // }
  // &.wday-1 {
  // }
  // &.wday-2 {
  // }
  // &.wday-3 {
  // }
  // &.wday-4 {
  // }
  // &.wday-5 {
  // }
  // &.wday-6 {
  // }

  & .today {
    @apply bg-legacyColor-secondary-50;

    &:active {
      @apply bg-legacyColor-neutral-2-700;
    }
  }

  & .future {
    @apply border-0;
  }

  // &.start-date {
  // }

  // &.prev-month {
  // }
  // &.next-month {
  // }
  // &.current-month {
  // }

  // & .has-events {
  // }
}
