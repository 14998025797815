mobile-week-calendar {
  .tab-1:checked ~ .tabs > .tab-label-1 span:nth-of-type(2),
  .tab-2:checked ~ .tabs > .tab-label-2 span:nth-of-type(2),
  .tab-3:checked ~ .tabs > .tab-label-3 span:nth-of-type(2),
  .tab-4:checked ~ .tabs > .tab-label-4 span:nth-of-type(2),
  .tab-5:checked ~ .tabs > .tab-label-5 span:nth-of-type(2),
  .tab-6:checked ~ .tabs > .tab-label-6 span:nth-of-type(2),
  .tab-0:checked ~ .tabs > .tab-label-0 span:nth-of-type(2) {
    @apply bg-primary-100 rounded-full;
  }

  .tab-1:checked ~ .tab-content-container > .tab-content-1,
  .tab-2:checked ~ .tab-content-container > .tab-content-2,
  .tab-3:checked ~ .tab-content-container > .tab-content-3,
  .tab-4:checked ~ .tab-content-container > .tab-content-4,
  .tab-5:checked ~ .tab-content-container > .tab-content-5,
  .tab-6:checked ~ .tab-content-container > .tab-content-6,
  .tab-0:checked ~ .tab-content-container > .tab-content-0 {
    display: block !important;
  }
}
