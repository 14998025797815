.icon-sm {
  @apply w-[16px] h-[16px] min-w-[16px] min-h-[16px] fill-current;

  & * {
    @apply fill-current;
  }
}

.icon {
  @apply w-[24px] h-[24px] min-w-[24px] min-h-[24px] fill-current;

  & * {
    @apply fill-current;
  }
}

.icon-lg {
  @apply w-[32px] h-[32px] min-w-[32px] min-h-[32px] fill-current;

  & * {
    @apply fill-current;
  }
}
