.multiselect__container {
  font-family: sans-serif;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1.75rem;
  padding-left: 0.5rem;
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    no-repeat right #ddd;
  background-color: transparent;
  border-radius: 0.25rem;
  appearance: none;
  border: 1px solid rgb(218 218 218);
  outline-offset: 2px;
  outline: transparent solid 2px;
  color: rgb(55 61 63);
  line-height: 1.5;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  cursor: pointer;
}

.multiselect__no-result {
  color: rgb(112 112 112);
  padding: 0.75rem;
  font-size: 0.875rem;
}

.multiselect__addable-button {
  padding: 0.5rem;
  text-decoration-line: underline;
  color: rgb(55 61 63);
  cursor: pointer;
}

.multiselect__addable-button:hover {
  color: rgb(29 115 186);
}

.multiselect__container:focus-within {
  border-color: rgb(44 141 222);
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.multiselect__container--disabled {
  background-color: rgb(240 240 240);
  cursor: not-allowed;
}

.multiselect__container:hover {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.multiselect__hidden {
  display: none;
}

.multiselect__preview {
  width: -webkit-stretch;
  width: -moz-available;
  width: -webkit-stretch;
  gap: 0.5rem;
  font-size: 0.875rem;
  display: flex;
  flex-wrap: wrap;
}

.multiselect__pill {
  max-width: stretch;
  color: rgb(55 61 63);
  font-size: 0.75rem;
  padding-left: 0.5rem;
  background-color: rgb(250 249 244);
  border: 1px solid #f0f0f0;
  border-radius: 0.25rem;
  display: flex;
}

.multiselect__pill-delete {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  align-items: center;
  display: flex;
  margin-left: 0.25rem;
}

.multiselect__pill-delete:hover {
  background-color: rgb(254 178 178);
}

.multiselect__pill:hover svg {
  fill: rgb(246, 71, 71);
}

.multiselect__pill-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.multiselect__input-container {
  display: flex;
}

.multiselect__focused {
  background-color: #f4fbff;
}

.multiselect__placeholder {
  color: rgb(174 174 174);
  font-style: italic;
  padding-left: 0.25rem;
  opacity: 1;
}

.multiselect__addable {
  text-decoration-line: underline;
  color: rgb(112 112 112);
  text-align: center;
}

.multiselect__addable:hover {
  color: rgb(55 61 63);
}

.multiselect__search {
  width: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border: transparent;
  color: rgb(55 61 63);
  font-size: 0.875rem;
  padding-left: 0.25rem;
}

.multiselect__search:focus::placeholder {
  color: rgb(112 112 112);
}

.multiselect__search:disabled {
  background-color: rgb(240 240 240);
  cursor: not-allowed;
}

.multiselect__search::placeholder {
  font-style: italic;
  opacity: 1;
  color: rgb(174 174 174);
}

.multiselect__list {
  max-height: 400px;
  overflow-y: auto;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.multiselect__list li {
  display: block;
  align-items: center;
  font-size: 0.875rem;
  color: rgb(112 112 112);
  border-top-width: 1;
}

.multiselect__list li:first {
  border-top-width: 0;
}

.multiselect__list li:hover {
  background-color: rgb(244 251 255);
}

.multiselect__list li label {
  padding: 0.75rem;
  align-items: center;
  cursor: pointer;
  display: flex;
}

.multiselect__list li:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.multiselect__list li:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.multiselect__list li input {
  margin-right: 0.75rem;
}

.multiselect__dropdown {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: rgb(255 255 255);
  border: 1px solid rgb(218 218 218);
  width: 100%;
  border-radius: 0.25rem;
  display: none;
  margin-top: 0.5rem;
  z-index: 50;
  position: absolute;
}

.multiselect__dropdown--open {
  display: block;
}
