.main-nav {
  position: fixed;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.mobile.main-nav-active {
  top: 0;
}
.mobile.main-nav-inactive {
  top: -100vh;
}

.desktop.main-nav-active {
  transform: translate(0);
}
.desktop.main-nav-inactive {
  transform: translate(-100%);
}

.main-nav-bg-fadeOut {
  @apply invisible opacity-0;

  transition: visibility 0s linear 300ms, opacity 300ms;
}

.main-nav-bg-fadeIn {
  @apply visible opacity-50;

  transition: visibility 0s linear 0s, opacity 300ms;
}

.monash {
  .main-nav.desktop {
    &.is-shrink {
      .toggle-active {
        @apply bg-[#c0c0c0];
      }
    }
  }
  .menu-nav-dropdown {
    @apply bg-[#c0c0c0];
  }
}

.default {
  .main-nav.desktop {
    &.is-shrink {
      .toggle-active {
        @apply bg-primary-300;
      }
    }
  }

  .menu-nav-dropdown {
    @apply bg-primary-100;
  }
}

.main-nav.desktop {
  transition: width 0.5s;

  &:not(.is-shrink) {
    @apply w-[300px];

    .main-nav-link__menu-icon {
      @apply hidden opacity-0;
    }
  }

  &.is-shrink {
    @apply w-[70px];

    .toggle-active {
      @apply bg-[#c0c0c0];
    }

    .user-dropdown,
    .main-nav-link__expand-item,
    .main-nav-link__logo {
      @apply hidden opacity-0;
    }
  }
}
