.messages {
  display: flex;
  flex-flow: column wrap;
}

.messages .bubble {
  margin: 0.0625em;
  max-width: 75%;
}

.messages .bubble .content {
  border-radius: 1em;
  padding: 0.5em 0.75em;
  overflow-wrap: break-word;
}

.messages .bubble .timestamp {
  @apply text-overline text-legacyColor-neutral-1-300 my-1;
}

.messages .bubble.sender {
  align-self: flex-end;

  .content {
    @apply bg-primary-100;

    &.failed {
      border-color: red;
      border-width: 1px;
    }
  }

  .timestamp {
    @apply text-right;
  }
}

.messages .bubble.receiver {
  align-self: flex-start;

  .content {
    @apply bg-neutral-100;
  }
}
