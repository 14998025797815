.sliding-panel {
  @apply bg-white w-0;

  &-inner {
    @apply flex-auto flex flex-col overflow-hidden;
    min-width: 360px;
  }

  @screen md {
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.is-open {
    @apply w-full;

    @screen md {
      width: 680px;
    }
  }
}

.side-sheet {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate3d(105%, 0, 0);
  transition: transform 300ms;
  z-index: 20;
  --tw-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.side-sheet.is-open {
  transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
  .side-sheet {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    transform: none;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 0;
    will-change: width;
  }
}
