/* app/javascript/stylesheets/application.scss */
@import "tailwindcss/base";
@import "tailwindcss/components";

@import "fonts";
/* Add any custom CSS here, before utilities */
@import "components/avatars.scss";
@import "components/pagination";
@import "components/tooltip";
@import "components/headers";
@import "components/session_pages";
@import "components/simple_calendar";
@import "components/typography";
@import "components/buttons";
@import "components/form";
@import "components/flash";
@import "components/icons";
@import "components/table_list";
@import "components/switch_button";
@import "components/choices";
@import "components/dropzone";
@import "components/cropper";
@import "components/community_app/mobile_week_calendar";
@import "components/messages";
@import "components/client_app/nested_form";
@import "components/ck_editor";
@import "components/multiselect";
@import "components/tab";
@import "components/mentions";
@import "components/background.scss";
@import "components/sliding_panel.scss";
@import "./left_nav.scss";
@import "./main_nav_animation.scss";

@import "tailwindcss/utilities";

/*! purgecss start ignore */
@import "choices.js/public/assets/styles/choices.css";
/*! purgecss end ignore */
