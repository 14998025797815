:root {
  --header-radius: 80px;
}

.default {
  header {
    @apply bg-primary-50;
    position: relative;
  }
}

.monash {
  header {
    background-color: #f1f1f1;
    position: relative;
  }
}

$breakpoint-md: 768px;
@media (max-width: $breakpoint-md) {
  :root {
    --header-radius: 60px;
  }
}
