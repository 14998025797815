@mixin new-button {
  @apply px-4 font-bold  inline-flex items-center justify-center no-underline whitespace-nowrap transition-all duration-150 ease-out cursor-pointer;
  height: 36px;
  line-height: 36px;
  letter-spacing: 0.03em;

  &:focus {
    @apply outline-none;
  }
}

@mixin button-small {
  @apply text-sm font-normal px-2;
  height: 32px;
}

@mixin disabled-button {
  @apply bg-legacyColor-neutral-2-100 text-legacyColor-neutral-2-500 cursor-not-allowed;
}

.pure-button {
  @apply px-4 rounded-full font-bold text-secondary-700 inline-flex items-center justify-center no-underline whitespace-nowrap transition-all duration-150 ease-out cursor-pointer;
  height: 36px;
  line-height: 36px;
  letter-spacing: 0.03em;

  &:focus {
    @apply outline-none;
  }
}

.btn-disabled {
  @include new-button;
  @include disabled-button;
}

.btn-tertiary {
  @include new-button;
  @include button-small;
  @apply text-legacyColor-neutral-1-500 bg-legacyColor-primary-100;

  &:hover {
    @apply bg-legacyColor-primary-200;
  }

  &:active {
    @apply bg-legacyColor-primary-600;
  }

  &:disabled {
    @include disabled-button;
  }
}

.btn-black {
  @include new-button;
  @apply text-legacyColor-neutral-2-50 bg-neutral-700 rounded-none;

  &:hover {
    @apply bg-shade-100;
  }

  &:active {
    @apply bg-neutral-700;
  }

  &:disabled {
    @include disabled-button;
  }
}

@mixin new-disabled-button {
  @apply text-neutral-500 cursor-not-allowed;
}

@layer components {
  .btn-primary,
  .btn-secondary {
    @include new-button;
  }

  .default .btn-primary {
    @apply bg-primary-100 rounded-full text-secondary-700;

    &:hover {
      @apply bg-primary-50;
    }

    &:active {
      @apply bg-primary-200;
    }

    &:disabled {
      @include new-disabled-button;
      @apply bg-neutral-200;
    }
  }

  .default .turn-to-day-btn {
    @apply border-primary-100 rounded-full;
  }

  .monash {
    .btn-primary {
      @apply bg-primary-700 text-white;

      &:hover {
        @apply underline;
      }

      &:disabled {
        @apply bg-neutral-700;
      }
    }
    .turn-to-day-btn {
      @apply border-primary-500;
    }
  }

  .default .btn-secondary {
    @apply bg-shade-50 rounded-full outline outline-2 outline-offset-[-2px] outline-secondary-100;

    &:hover {
      @apply outline-secondary-300;
      background-color: initial;
    }

    &:active {
      @apply outline-secondary-600;
    }

    &:focus {
      @apply outline-secondary-600 outline-offset-[-2px];
    }

    &:disabled {
      @include new-disabled-button;
      @apply bg-neutral-50 border-neutral-500 outline-neutral-500;
    }
  }

  .monash .btn-secondary {
    @apply bg-shade-50 outline outline-2 outline-primary-700;

    &:hover {
      @apply underline;
    }
  }

  .btn-sm {
    @apply py-2 px-4 text-sm uppercase leading-[1.0625rem] tracking-[0.08em];
  }
  .btn-md {
    @apply py-3 px-[1.125rem] text-base uppercase leading-[1.25rem] tracking-[0.08em];
  }
  .btn-lg {
    @apply py-3.5 px-5 text-lg uppercase leading-[1.3125rem] tracking-[0.08em];
  }
}
