.table-list {
  @apply border-separate table-auto w-full;

  border-spacing: 0 10px;

  & > thead > tr > th {
    @apply uppercase leading-5 text-left px-5 first:pl-6 last:pr-6;
  }

  & > tbody > tr > td {
    @apply border
        border-legacyColor-neutral-2-500
          border-l-0
          border-r-0
          first:border-l
          last:border-r
          first:rounded-tl
          first:rounded-bl
          last:rounded-br
          last:rounded-tr
          py-4
          px-5
          first:pl-6
          last:pr-6;
  }
}

.table-underline {
  @apply table-fixed w-full;

  border-spacing: 0 10px;

  & > thead > tr > th {
    @apply uppercase leading-5 text-left py-5 border-b-2 border-neutral-100;
  }

  & > tbody > tr > td {
    @apply py-5 pr-2 border-b-2 border-neutral-100;
  }
}
