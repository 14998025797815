@import "dropzone/dist/dropzone.css";

.dropzone .dz-preview .dz-image {
  @apply w-52 h-52;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  @apply bg-neutral-50/0;
}

.dropzone .dz-details {
  @apply hidden;
}

.dropzone {
  @apply border-0;
}

// classes added to dynamic elements
.dropzone .dz-upload {
  background-color: #39a490 !important;
}

.dropzone .dz-progress {
  border: none !important;
  background-color: #ddf8f4 !important;
}
