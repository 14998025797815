/* assistant-regular - latin-ext */
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../fonts/assistant-v8-latin-ext-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/assistant-v8-latin-ext-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* assistant-600 - latin-ext */
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("../fonts/assistant-v8-latin-ext-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/assistant-v8-latin-ext-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* assistant-700 - latin-ext */
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../fonts/assistant-v8-latin-ext-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/assistant-v8-latin-ext-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* assistant-800 - latin-ext */
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 800;
  src: local(""),
    url("../fonts/assistant-v8-latin-ext-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/assistant-v8-latin-ext-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-100 */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: local(""), url("../fonts/Inter-Thin.ttf") format("ttf");
}

/* inter-200 */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../fonts/Inter-ExtraLight.ttf") format("ttf");
}

/* inter-300 */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/Inter-Light.ttf") format("ttf");
}

/* inter-400 */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/Inter-Regular.ttf") format("ttf");
}

/* inter-500 */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/Inter-Medium.ttf") format("ttf");
}

/* inter-600 */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/Inter-SemiBold.ttf") format("ttf");
}

/* inter-700 */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/Inter-Bold.ttf") format("ttf");
}

/* inter-800 */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/Inter-ExtraBold.ttf") format("ttf");
}

/* inter-900 */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../fonts/Inter-Black.ttf") format("ttf");
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/HelveticaNeueLight.ttf") format("ttf");
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/HelveticaNeueMedium.ttf") format("ttf");
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/HelveticaNeueBold.ttf") format("ttf");
}
