ul.pagination {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  width: 700px;
}

ul.pagination li a {
  border-radius: 7px;
  padding: 0.1rem 1rem;
  border: gray 1px solid;
  cursor: pointer;
}
ul.pagination li.previous a,
ul.pagination li.next a,
ul.pagination li.break a {
  border-color: transparent;
}
ul.pagination li.selected a {
  background-color: rgba(26, 83, 75);
  border-color: transparent;
  color: white;
  min-width: 32px;
}
ul.pagination li.disabled a {
  color: grey;
}
ul.pagination li.disable,
ul.pagination li.disabled a {
  cursor: default;
}
